window.PLAYERTOKENS = [
	["fas fa-thumbs-up"],
	["fas fa-heart"],
	["fas fa-paint-brush"],
	["fas fa-cocktail"],
	["far fa-hand-peace"],
	["fas fa-feather-alt"],
	["fas fa-trophy"],
	["fas fa-mug-hot"],
	["fas fa-gift"]
];

var exp_levelminexp = [];

window.writelog = function(msg, ...args){
		try {
			
			// console.log(new Date().getTime(), msg, ...args);
		
			// Sentry.addBreadcrumb({
				// message: msg,
				// data: args.length == 0 ? null : {"args": args.map(v => typeof v == "string" ? v : JSON.stringify(v)).join(", ")}
			// });
			
		} catch(err) {
			console.error(err);
		}		
	}

window.localstorage_get = function (k){
		writelog("localstorage_get", k);
		
		try {
			
			return localStorage.getItem(k);
			
		} catch(err) {
			reportexeption(false, err);
		}
	}
	
window.localstorage_set = function (k, v){
		writelog("localstorage_set", k, v);
		
		try {
			
			localStorage.setItem(k, v);
			
			return true;
		} catch(err) {
			
			reportexeption(false, err);
			
			return false;
		}
	}
	
window.sessionstorage_get = function(k, removeafterget = false){
		writelog("sessionstorage_get", k);
		
		try {
			if( removeafterget ){
				var v = sessionStorage.getItem(k);
				
				sessionStorage.removeItem(k);
				
				return v;
			} else {
				return sessionStorage.getItem(k);
			}
			
		} catch(err) {
			reportexeption(false, err);
		}
	}
	
window.sessionstorage_set = function (k, v){
		writelog("sessionstorage_set", k, v);
		
		try {
			
			return sessionStorage.setItem(k, v);
			
		} catch(err) {
			reportexeption(false, err);
		}
	}
	

window.exp_getlevelinfo = function(exp){
	for( var i = 0; i < exp_levelminexp.length; i++ ){
		if( exp < exp_levelminexp[i] ){
			return {
				"level": i, 
				"levelexpcurrent":exp_levelminexp[i - 1] || 0,
				"levelexpnext": exp_levelminexp[i]
			};
		}
	}

	return {
		"level": 1,
		"levelexpcurrent": 1,
		"levelexpnext": 1
	};
}

window.exp_fillarr = function(){
	exp_levelminexp[0];
	
	var n = 0;
	for( var i = 1; i < 200; i++){
		n += i * i * 5 + 100;
		exp_levelminexp[i] = n;
	}
	
}

exp_fillarr();